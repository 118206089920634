
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

body {
   background: rgb(0, 0, 0);
   --color1: rgb(167, 170, 176);
   color: var(--color1);
   font-family: "Inter", sans-serif;
   font-weight: 500;
}
img {
   max-width: 100%;
}
p,
ul,
a {
   color: var(--color1);
}
body,
input,
textarea,
select,
button {
   font-size: 14px;
   /* font-family: sans-serif; */
   color: var(--color1);
}
.container {
   max-width: 1320px;
}
ul {
   padding: 0;
}
ul li {
   list-style-type: none;
}
.logo img {
   max-width: 104px;
}
a {
   text-decoration: none;
}
a:hover {
   opacity: 0.8;
}
header nav ul li a {
   padding: 0px 20px;
   color: var(--color1);
   font-weight: 500;
}
header .container {
   max-width: 1227px;
}
header a {
   font-size: 16px;
   font-weight: 500;
}
.btn {
   font-size: 16px;
   background: linear-gradient(97.1367deg, rgb(33, 33, 33) 0%, rgb(66, 66, 66) 193.29%);
   color: #ffffff;
   border-radius: 100px;
   position: relative;
   font-weight: 600;
   overflow: hidden;
   border: none;
}

.header .btn {
   padding: 10px 20px;
   border: 0;
}

.header {
   z-index: 999;
   backdrop-filter: blur(40px); /* Apply blur */
   background: rgba(0, 0, 0, 0.3); /* Add semi-transparent background */
   transition: all 0.3s ease;
   pointer-events: auto;
   position: relative;
}

.header.header-sticky {
   position: sticky;
   top: 0;
   left: 0;
   width: 100%;
}
.creditsleft{
   font-size:16px;
   margin:0px;
}

@media only screen and (max-width: 600px) {
header.is-sticky {
   padding-block: 12px !important;
}
   .header.header-sticky {
      padding-top:12px !important;
      padding-bottom:12px !important;
      }
}
   
.btn.btn-sq {
   border-radius: 8px;
   padding: 15px 20px 15px 20px;
   font-size: 16px;
   line-height: 16px;
   font-weight: 500;
   display: flex;
   align-items: center;
   width: fit-content;
}
.btn.btn-border {
   border: 1px solid rgba(207, 212, 220, 0.1);
   background: linear-gradient(94deg, rgba(66, 66, 66, 0.5) 0%, rgba(66, 66, 66, 0.2) 100%);
   box-shadow: 0 1px 2px #1018280d;
   padding: 10px;
   border-radius: 8px;
   min-width: 250px;
   text-align: center;
}
.btn.btn-border img {
   height: 22px;
   width: auto;
   margin-right: 14px;
}
.btn.btn-t {
   background: transparent;
   color: var(--color1);
}
.btn.btn-sq img,
.btn svg {
   height: 17px;
    width: auto;
    margin-top: 2px;
    margin-bottom: 2px;
}
.btn svg {
   margin-left: 5px;
}
.btn.btn-t:hover {
   background-color: rgba(255, 255, 255, 0.2);
}
.btn.btn-without-hover {
   background: transparent !important;
   color: #a7aab0 !important;
}
.btn.btn-without-hover::after {
   display: none !important;
}
.btn:after {
   content: "";
   width: 15px;
   height: 100%;
   position: absolute;
   left: -22px; /* Positioned off-screen initially */
   opacity: 0;  /* Initially invisible */
   top: 0;
   background: radial-gradient(50% 50%, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
   filter: blur(12px);
   transform: none;
   transform-origin: 50% 50% 0px;
   transition: left 0.6s ease-out, opacity 0.3s ease-out; /* Animate left for moving, opacity for fade */
}

.btn:hover:after {
   opacity: 1; /* Instantly visible on hover */
   left: 100%; /* Move the glow to the right */
   transition: left 0.6s ease-out, opacity 0s ease-in; /* Ensure opacity appears instantly */
}

.btn:hover:after {
   opacity: 1; /* Keep opacity at 1 when hovered */
}

.btn:after {
   left: -22px; /* Move the glow back to the left */
   opacity: 0;  /* Smooth fade-out */
   transition: left 0.6s ease-out, opacity 0.3s ease-out 0.5s; /* Keep the left transition for the return movement */
}
.btn:hover {
   color: #fff;
   opacity: 1;
}
.btn:hover:after {
   opacity: 1;
   left: 100%;
}
.space-mr {
   margin: 180px 0px;
}
.space-pd {
   padding: 180px 0px;
}
.frame-image-sc .container {
   max-width: 1280px;
}
.extract-sc {
   padding: 0 0 140px;
}

.extract-sc-in {
   max-width: 1012px;
   margin: 0 auto;
}
.extract-sc .container {
   max-width: 1030px;
}
.extract-sc .rgt img {
   border-radius: 35px;
   height: 280px!important;
   width: 330px!important;
   float: right;
}
.extract-sc .lft {
   padding-right: 100px;
}
h2,
.h2 {
   font-size: 40px;
}
body,
p {
   font-size: 18px;
}
.footer {
   background-color: rgb(8, 8, 8);
   border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.copy-right-sc {
   border-top: 1px solid rgba(255, 255, 255, 0.1);
   padding-top: 30px;
   margin-top: 30px;
}
footer nav ul li a {
   margin-right: 25px;
   color: white;
   font-weight: 500;
}
.footer-links svg {
   height: 25px;
   width: 25px;
}
.footer-links path {
   fill: var(--color1);
}
.footer-links ul li a:hover {
   background-color: rgba(255, 255, 255, 0.1);
   border-radius: 8px;
}
.footer-links ul li a {
   padding: 8px;
}
.top-title {
   text-transform: uppercase;
   letter-spacing: 0.36em;
   font-size: 16px;
   background: -webkit-linear-gradient(#eee, #333);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}
.table-sc h1 {
   background: -webkit-linear-gradient(#eee, #333);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}
.outer-banner {
   overflow: visible;
}
.banner {
   padding-top: 120px;
   position: sticky;
   overflow: visible;
   position: -webkit-sticky;
   top: 0;
   z-index: 1;
}
.frame-image-sc img {
   position: relative;
   z-index: 2;
   /* background-color: rgb(0,0,0); */
}
.frame-image-sc {
   position: relative;
   z-index: 3;
}
.frame-image-sc .wrapper {
   background-color: transparent;
}
h1,
.h1 {
   font-size: 52px;
   font-family: "Inter Tight", sans-serif;
   font-weight:400;
   color: #e6e6e6;
   font-size: 54px;
}
.form-control {
   background: rgba(38, 38, 38, 0.7) !important;
   color: #a7aab0;
   box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px inset, rgb(38, 38, 38) 0px 0px 0px 1px inset !important;
   border: none;
   font-size: 14px;
   line-height: 18px;
   padding: 13px;
   border: 1px solid transparent;
    transition: border-color 0.2s ease;

}
.form-control::placeholder {
   color: #a7aab0;
   border: 1px solid transparent!important;
}
.form-control:focus{
   color: #a7aab0;
   border: 1px solid transparent!important;
}
.banner-form-sec .form-control {
   min-width: 360px;
}
.banner-form-sec .btn {
   font-size: 16px;
   padding: 11px 20px;
   background-color: #0d0d0d !important;
   border-color: #1a1a1a;
}
.banner-form-sec .btn svg {
   margin-left: 8px;
}
.banner-form-sec .btn svg path {
   fill: white;
}
.or-sec {
   width: 500px;
   position: relative;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 18px;
   font-size: 14px;
   margin-top:30px!important;
   color:white;
}
.or-sec:before {
   content: "";
   width: calc(50% - 30px);
   height: 1px;
   background-color: rgb(255, 255, 255, 0.2);
   position: absolute;
   left: 0;
   top: 50%;
}
.or-sec:after {
   content: "";
   width: calc(50% - 30px);
   height: 1px;
   background-color: rgb(255, 255, 255, 0.2);
   position: absolute;
   right: 0;
   top: 50%;
}

.signup-text {
   font-size: 14px;
   margin-bottom: 18px;
}

.g-f-button {
   max-width: 265px;
   width: 100%;
}
body .slick-dots li button:before {
   content: none;
}
body .slick-dots li button {
   width: 6px;
   height: 6px;
   border-radius: 50%;
   background-color: rgb(255, 255, 255, 0.7);
}
body .slick-dots .slick-active button {
   background-color: white;
}
body .slick-dots {
   bottom: -85px;
}
.modal.active {
   display: block;
   opacity: 1;

   background-color: rgba(0, 0, 0, 0.9);
}
.modal-dialog {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
}
.close {
   background-color: black;
   border: none;
   color: white;
   font-weight: 600;
   font-size: 20px;
   position: absolute;
   right: 20px;
   top: 20px;
   z-index: 2;
}
.amounsc1 {
   font-weight: 500;
   font-size: 14px;
}
.amounsc1 input {
   background-color: rgba(255, 255, 255, 0.06);
   border-radius: 5px;
   color: white;
   padding: 5px 10px;
   border: none;
   width: 60px;
}
.more-settings {
   width: fit-content !important;
   max-width: 100%;
}
.more-settings.active .settings-inner {
   width: fit-content;
   opacity: 1;
   height: auto;
   transition: all 0.5s;
}
.more-settings .btn {
   cursor: pointer;
   font-weight: 400;
    font-size: 16px;
}
.more-settings.active .btn {
   text-align: center;
   justify-content: center;
}
.ross {
   cursor: pointer;
}
/* .settings-inner{
  opacity: 0;height: 0;transition: all 0.5s;
  overflow: hidden;
} */
input:focus {
   outline: none !important;
   border:1px solid #000!important;
}
textarea:focus {
   outline: none !important;
   border:1px solid #000!important;
}
select:focus {
   outline: none !important;
   border:1px solid #000!important;
}
/* .outer-more-settings{
  width: 250px;
} */
.btm-shadow {
   background: -webkit-linear-gradient(#eee, #333);
   background-clip: border-box;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}
body.hidden #root {
   height: 0px;
   overflow: hidden;
}
.bi-twitter-x {
      padding-top: 4px;
    padding-left: 4px;
   }
.signedinundergenerate {
      min-height:200px;
   }
@media (min-width: 1200px) {
   .footer {
      padding-top: 100px;
   }
   .serach_result_main_mobile {
    margin-top: 5px;
}
}
@media (min-width: 1840px) {
   .banner {
      padding-top: 180px;
   }
}
@media (max-width: 991px) {
   h1,
   .h1 {
      font-size: 40px;
   }
   h2,
   .h2 {
      font-size: 30px;
   }
   .banner {
      padding-top: 100px;
   }
   .space-pd {
      padding: 100px 0px;
   }
   .space-mr {
      margin: 100px 0px;
   }
}

@media (max-width: 767px) {
   .footerbtn {
      display:none;
   }
   .signedinundergenerate {
      min-height:50px;
   }
   .creditsleft {
      margin-left:20px!important;
      margin-right: 10px !important;
   }
   .nomobile {
      display:none;
   }
   body .google_interface {
      padding-bottom: 0px;
   }
   body .capsul_tab {
      flex-wrap: wrap;
   }
   body .right {
      padding-right: 0;
   }
   body .header_ser {
      height: auto;
      padding-bottom: 0;
   }
   .google_serach {
      margin: 15px 0px;
   }
   .space-pd {
      padding: 80px 0px;
   }
   .space-mr {
      margin: 80px 0px;
   }
   h2,
   .h2 {
      font-size: 30px;
   }
   .extract-sc .lft {
      padding-right: 0;
   }
   body,
   p,
   ul,
   a {
      font-size: 14px;
   }
   .footer {
      text-align: center;
   }
   footer nav ul li a {
      padding: 0;
      font-size: 16px;
   }
   footer nav ul {
      flex-wrap: wrap;
   }
   footer nav ul li {
      width: 100%;
      margin: 15px 15px;
   }
   .btm-btn-sc .btn {
      display: block;
      width: 100%;
      margin: 15px 0px;
   }
   .or-sec {
      width: 100%;
   }
   .banner-form-sec .btn {
      width: 100%;
      text-align: center;
   }
   h1,
   .h1 {
      font-size: 30px;
   }
   /* header.active {
      position: fixed;
      background: black;
      height: 100%;
      z-index: 5;
      width: 100%;
      left: 0;
      top: 0;
   } */
   /* header.active ul li {
      margin: 35px 0px;
   }
   header.active ul li a {
      font-size: 16px;
      font-weight: 600;
   }
   header.active .btn-sec a {
      display: block;
      width: 100%;
      margin: 10px 0px;
      color: white;
      background: linear-gradient(97.1367deg, rgb(33, 33, 33) 0%, rgb(66, 66, 66) 193.29%);
   }
   .active-mb {
      max-height: 0px;
      opacity: 0;
      transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out;
   }

   .active .active-mb {
      max-height: 500px;
      opacity: 1;
   } */

   .hamburger-react {
      margin-left: auto;
   }
   .banner {
      padding-top: 60px;
   }
   .banner-form-sec .form-control {
      min-width: auto;
      width: 100%;
   }
}

@media (max-width: 991px) {
   header.active {
      position: fixed;
      height: 100%;
      z-index: 5;
      width: 100%;
      left: 0;
      top: 0;
   }
   .header-mobile-nav ul li {
      margin: 45px 0px;
   }
   .header-mobile-nav ul li a {
      font-size: 16px;
      font-weight: 600;
   }
   .header-mobile-nav .btn-sec a {
      display: block;
      width: 100%;
      margin: 10px 0px;
      color: white;
      background: linear-gradient(97.1367deg, rgb(33, 33, 33) 0%, rgb(66, 66, 66) 193.29%);
   }
   .header-mobile-nav {
      height: 0;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      transform: translateY(-30px);
      overflow: auto;
   }

   header.active .header-mobile-nav {
      height: 360px; /* Adjust this value to a large enough height for the content */
      transform: translateY(0);
      opacity: 1;
      overflow: auto;
   }
}

@media (max-width: 1199px) {
   .banner {
      position: relative;
   }
}

.google_serach_input::placeholder {
   color: #fff;
   opacity: 1; /* Firefox */
}

.google_serach_input::-ms-input-placeholder {
   /* Edge 12 -18 */
   color: #fff;
}
.google_logo img {
   object-fit: contain;
}

.google-search-close {
   width: 20px;
   color: white;
}
.google-search-close svg {
   fill: #9e9e9e;
}
.wrapper {
   background: #000000;
   display: flex;
   align-items: center;
   font-family: Arial, sans-serif;
   z-index: 3;
}

.desktop_mockup {
   max-width: 1100px;
   width: 100%;
   margin: 0 auto;
   padding: 8px;
   border-radius: 20px;
   margin-right: 100px;
   position:relative;
   box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.2), /* Inner glow to simulate lighting */
              0 4px 15px rgba(0, 0, 0, 0.5); /* Outer shadow to give depth */
   background: linear-gradient(to bottom, rgba(50, 50, 50, 1), rgba(0, 0, 0, 1));
}

.google_interface {
   background: #1f1f1f;
   width: 100%;
   height: 100%;
   border-radius: 15px;
   -webkit-box-shadow: -7px -7px 24px -9px rgba(0, 0, 0, 0.43);
   -moz-box-shadow: -7px -7px 24px -9px rgba(0, 0, 0, 0.43);
   padding-bottom: 25px;
}
.google_interface_desktop {
   border-left: 3px solid #161616;
    border-bottom: 3px solid #161616;
}

.header_area {
   border-bottom: 1px solid #313131;
   padding-bottom:5px;
}

.header_ser {
   height: 70px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   padding-top: 0px;
   padding-left: 20px;
   border-top: 3px solid #000;
    border-right: 3px solid #000;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.serach_wrapper {
   width: 70%;
   display: flex;
   align-items: center;
   gap: 40px;
}

.google_serach {
   width: 100%;
   height: 40px;
   background: #303135;
   border-radius: 50px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 20px;
}

.ser {
   width: 90%;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.google_serach_input {
   width: 100%;
   line-height: 40px;
   background: transparent;
   border: none;
   outline: none;
   padding: 0 20px;
}

.end_area {
   width: 15%;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   border-left: 1px solid #535353;
   margin-left: 5px;
}

.goog_btn {
   background: transparent;
   border: 0;
   outline: 0;
   display: inline-block;
}
.mic img {
   width: 12px;
}
.serch_ico img {
   width: 15px;
}

.lens img {
   width: 25px;
}

.close {
   width: 20px;
}
.close svg {
   fill: #9e9e9e;
}

.brand_logo_img {
   width: 26px;
   height: 26px;
   object-fit: cover;
   display: flex;
   align-items: center;
   justify-content: center;
}

.google_serach_result {
   padding-left: 154px;
   padding-top: 25px;
   max-width: 600px;
   width: 100%;
}
.sponsored-text2 {
   font-weight:600;
   color:lightgray;
   font-size:12px;
   padding-bottom:7px;
}

.mock-site-link a {
   color: #99c3ff !important;
   border: none !important;
   cursor: pointer;
}

.brand_logo_img img {
   width: 100%;
   height: 100%;
   border-radius: 20px;
   margin-top:-5px;
}

.serach_item {
   display: flex;
   align-items: center;
   gap: 10px;
   margin-bottom:2px;
}

.barnd_name h6 {
   font-family: Arial, sans-serif;
   font-size: 12px;
   line-height: 14px;
   color: #dadce0;
   font-weight: normal;
   margin-bottom: -5px !important;
}
.sponsored-text {
   border-radius: 3px;
   margin-right: 8px;
   margin-bottom: 8px;
   line-height: 20px;
   background-color: transparent;
   color: #dadce0;
   display: inline-block;
   font-family: inherit;
   font-size: 14px;
   font-weight: bold;
   margin-left: 0px;
   white-space: nowrap;
}
.link {
   align-items: center;
   justify-content: center;
}

.website_link {
   display: inline-block;
   font-size: 11px;
   line-height: 18px;
   text-decoration: none;
   padding: 0;
   margin: 0;
}

.modal_dot {
   display: inline-block;
   font-size: 17px;
   padding-left:15px;
}
.serach_result_main a {
   text-decoration: none;
}

.serach_result_main a h1 {
   font-size: 17px;
   line-height: 26px;
   color: #99c3ff!important;
   text-decoration: none;
   font-weight: normal;
}

.serach_result_main a h1:hover {
   text-decoration: underline;
}

.info {
   font-size: 12px;
   line-height: 1.58;
   margin-top:-5px;
   margin-right:-90px;
   margin-bottom:0px;
}

.brand_name_highlisght {
   font-weight: bold;
}

.Ktlw8e {
   margin: 0;
   max-width: 568px;
   width: 100%;
   padding: 16px 0px 0px 16px;
}

.iCzAIb a {
   font-size: 17px;
   line-height: 26px;
   text-decoration: none;
   color: #fff;
}

.iCzAIb a:hover {
   border-bottom: 2px solid #fff;
}
.d-none-icon {
   display: none;
}

.right {
   display: flex;
   justify-content: space-evenly;
   gap: 20px;
   padding-right: 50px;
}

.search_labs {
   cursor: pointer;
}

.gmail {
   width: 30px;
   height: 30px;
}

.gmail img {
   width: 100%;
   height: 100%;
   border-radius: 50%;
}

.tabs {
   display: flex;
   justify-content: space-between;
   padding-left: 154px;
   max-width: 850px;
   width: 100%;
}
.tab_btn {
   outline: 0;
   cursor: pointer;
   background: transparent;
   border: none;
   padding: 7px 10px 7px 10px;
   font-size: 12px;
   color: #707070;
   font-family: Arial, sans-serif;
   font-weight: 501;
   margin-top:-10px;
}
.tab_btn.active {
   color: #99c3ff;
   border-bottom: 2px solid #99c3ff;
}

.capsul_tab {
   padding-left: 154px;
   padding-top: 10px;
}

.capsul {
   outline: 0;
   cursor: pointer;
   background: transparent;
   border: 1px solid #3b3b3b;
   padding: 6px 16px;
   font-size: 12px;
   border-radius: 20px;
   margin-right: 5px;
}

@media (max-width: 768px) {
   .more-settings {
    padding-top:0px!important;
   }
   .more-settings .btn{
    padding-top:10px;
      font-size:14px;
   }
   .extractbtn {
    float: left;
   }
   .more-setting-btn {
    float: left;
   }
   .extract-sc {
   padding: 0 0 10px;
    margin-top: -40px;
   }
   .extract-sc .rgt img {
   float:none;
      height: 220px !important;
    width: 100% !important;
    margin-bottom: 30px!important;
      margin-top: 10px!important;
   }
   .extract-sc .rgt {
      order: 1;
   }

   .extract-sc .lft {
      order: 2;
   }
   .header {
   padding-bottom: 12px !important;
   padding-top: 12px !important;
   }
   
   .generateform {
   margin-top:-25px;
   }
   .generatep {
      margin-left:20px;
      margin-right:20px;
      padding-bottom:55px;
   color:#8e9096!important;
   }
   .mt-md-0 {
   text-align:center;   
}
   .wrapper {
      padding: 10px;
      height: 100%;
   }

   .desktop_mockup {
      height: 100%;
   }
}

.desktop-mock-image {
   display: block;
}
.mobileonly {
   display: none!important;
}

@media only screen and (max-width: 600px) {
   .mobileonly {
   display: block!important;
}
   .desktoponly {
   display: none;
}
   .wrapper {
      padding: 10px;
      height: 100%;
   }
   .google_serach {
      padding: 20px 20px;
   }
   .google_serach_input {
      width: 100%;
   }
   .google_serach_result {
      padding: 20px 20px;
   }
   .serach_result_main h1 a {
      font-size: 18px;
      color: #c58af9;
      text-decoration: none;
      font-weight: normal;
   }
   .d-none {
      display: none;
   }

   .iCzAIb a {
      font-size: 16px;
      line-height: 20px;
   }
   .Ktlw8e {
      padding: 15px 0px;
      border-bottom: 1px solid #313131;
   }

   .capsul_tab {
      display: flex;
      flex-wrap: nowrap; /* Prevent wrapping to new lines */
      overflow-x: auto; /* Enable horizontal scrolling if needed */
      justify-content: space-between; /* Distribute buttons evenly */
      box-sizing: border-box;
      padding: 8px;
   }

   .capsul_tab .capsul {
      flex: 1; /* Make buttons take equal space */
      margin: 5px;
      font-size: 10px;
      padding: 10px 15px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-align: center;
      white-space: nowrap; /* Prevent text wrapping inside buttons */
      border: 1px solid #757575;
   }

   .tabs {
      flex-direction: row;
      padding: 0 20px;
      width: 100%;
   }
   .left_tabs {
      display: flex;
      flex-wrap: nowrap; /* Prevent wrapping to new lines */
      overflow-x: auto; /* Enable horizontal scrolling if needed */
      justify-content: space-between; /* Distribute buttons evenly */
      box-sizing: border-box;
      padding: 8px;
   }
   .left_tabs .tab_btn {
      flex: 1;
      margin: 5px;
      font-size: 10px;
      padding: 5px;
   }

   .serach_wrapper {
      width: 100%;
      flex-direction: column;
      gap: 0px;
   }
   .header_ser {
      height: 100px;
      flex-direction: column;
      width: 100%;
      padding: 20px 20px;
   }
   .google_logo img {
      height: 25px;
      margin-top: -5px;
   }
   .desktop_mockup {
      height: 100%;
   }
}

@media only screen and (max-width: 375px) {
   .wrapper {
      padding: 10px;
      height: 100%;
   }
   .desktop_mockup {
      height: 100%;
   }
}
.generatep {
      margin-top:20px;
   color:#8e9096!important;
   }
.extractbtn {
   padding: 8px 25px !important;
   }
.extractp {
   padding-top: 10px;
    margin-bottom: 0px !important;
    color: #a7aab0;
    font-weight: 400;
}
.extracttile {
   font-weight:400;
   -webkit-text-fill-color: #0000;
    background: linear-gradient(0deg, #fff0 -77.1856%, #fff 121.843%);
    -webkit-background-clip: text;
   background-image: linear-gradient(278deg, #fff0 -77.1856%, #fff 110.4947%);
   }
.btnswith {
   gap:10px;
   }
.nohovereffect:hover {
   opacity: 1 !important; /* Remove opacity change on hover */
   color: inherit !important; /* Prevent color change on hover */
   background: inherit !important; /* Prevent background change on hover */
}

.nohovereffect:after {
   display: none !important; /* Remove the glow effect on hover */
}
.mobilehomesec {
   margin:10px;
}
.nodisplay {
   display: none !important; /* Remove the glow effect on hover */
}
.serach_wapper{
   text-align:center;
   width:100%;
}
.gmailmobile{
   float:right;
   margin-top:-28px;
}
.serch_icomobile {
   margin-left: -12px!important;
    margin-right: 7px!important;
}
.mobile_mockup {
   margin:auto;
   max-width:330px!important;
   border-radius:40px;
}
.google_serach_result_mobile {
    padding: 15px;
   border-left: 4px solid #161616;
    border-right: 4px solid #161616;
}
.header_area_mobile {
   border-left: 4px solid #000;
    border-right: 4px solid #000;
    border-top: 4px solid #000;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
   padding-bottom: 5px;
    border-bottom: 1px solid #313131;
}
.capsul_tab_mobile {
    padding: 6px 0px 0px 13px;
   border-left: 4px solid #161616;
    border-right: 4px solid #161616;
   border-image: linear-gradient(to bottom, #000, #161616) 1;
}
.tabsmobile {
    padding: 0 0 0px 10px;
    margin-top: -13px;
}
.left_tabs_mobile {
    padding: 0px 0px 0px 0px;
}
.header_ser_mobile {
    padding: 17px 10px 0px 10px;
    margin-bottom: -10px;
}
.tab_btn_mobile {
    background: #0000;
    border: none;
    color: #707070;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    outline: 0;
    padding: 10px 6px 2px 8px;
}
.capsul_mobile {
    background: #0000;
    border: 1px solid #3b3b3b;
   color:white;
    border-radius: 20px;
    cursor: pointer;
    font-size: 10px;
    margin-right: 5px;
    outline: 0;
    padding: 3px 9px;
}
.sponsored-text2_mobile {
    color: #d3d3d3;
    font-size: 9px;
    font-weight: 600;
    padding-bottom: 5px;
}
.info_mobile {
    margin-top:-5px;
   padding-bottom:8px;
   font-size:11px;
   border-bottom: 1px solid #313131;
    margin-bottom: 0px;
}
.iCzAIb_mobile a {
   font-size:13px;
   color:lightgray!important;
   
}

.serach_result_main_mobile a h1 {
    color: #99c3ff !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
   margin-top:-4px;
   margin-bottom: 10px;
}
.tab_btn_mobile.active {
    text-decoration: underline;
    text-decoration-color: #fff;
    color: #fff;
    text-underline-offset: 5px;
    font-weight: 505;
}
.gmailmobile img {
    width: 24px;
    height: 24px;
}
.google_logo_mobile img {
    height: 20px;
}
.google_serach_mobile {
    background: #303135;
    border-radius: 50px;
    height: 32px;
    padding: 0px 0 0px 17px;
    width: 95%;
    margin: 13px auto 15px auto;
}
.modal_dot_mobile {
    font-size: 17px;
    padding-left: 15px;
    float: right;
    margin-right: -110px;
   margin-top:-5px;
}
.barnd_name_mobile h6 {
    color: #dadce0;
    font-family: Arial, sans-serif;
    font-size: 10px!important;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: -5px !important;
}
.website_link_mobile {
   display: inline-block;
   font-size: 10px!important;
   line-height: 18px;
   text-decoration: none;
   padding: 0;
   margin: 0;
}
.google_interface_mobile {
    border-radius:30px;
    padding-bottom:0px;
}
.mic_mobile img {
    width: 9px!important;
}
.google_serach_input_mobile {
    font-size:11px!important;
   color:lightgray;
   margin-left:-4px!important;
}
.brand_logo_img_mobile {
   width:26px;
   height:26px;
}
.Ktlw8e_mobile {
    border-bottom: 1px solid #313131;
    padding: 5px 0;
}
.serach_item2 {
    margin-top:35px;}
.settings-inner.overflow-hidden {
    width: 100%;
}
.google_serach_result_mobile2 {
    padding-top: 11px;
    border: 4px solid #161616;
   border-bottom-left-radius:30px;
   border-bottom-right-radius:30px;
   
}
.sitelinklast {
    border-bottom: 0px;
    margin-bottom: -10px;
}
.snippet {
   font-size: 11.5px;
    margin: 3px;
    border-radius: 5px;
    padding: 5px;
    color: #99c3ff !important;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 10px;
    font-weight: 300;
}
.snippetmobile {
    font-size: 11px;
    margin: 3px;
    border: 0.5px solid #333;
    border-radius: 5px;
    padding: 5px;
    color: #99c3ff !important;
}
.snippetdiv {
   margin-top:-1px;
}
.info_mobile2 {
   border-bottom:0px;
}
.snippetdivmobile {
    margin-top: -3px;
}
.arrowsitelink {
    float: right;
    margin-right: 10px;
    font-size: 26px;
    margin-top: -10px;
}
@media only screen and (min-width: 1250px) {
.mobile_mockup {
   position: absolute;
  right: 120px; /* Adjust to control distance from the right */
   top:75px;
}
}
/* Hide .mobile_mockup between 750px and 1250px */
@media only screen and (min-width: 750px) and (max-width: 1250px) {
  .mobile_mockup {
    display: none;
  }
.desktop_mockup {
    margin:auto;
  }
}

/* Show .mobile_mockup for screens larger than 1250px */
@media only screen and (min-width: 1250px) {
  .mobile_mockup {
    display: block; /* Ensure it's visible */
  }
   .barnd_name_mobile h6 {
      margin-bottom:-8px!important;
   }
}

/* Show .mobile_mockup for screens smaller than 750px */
@media only screen and (max-width: 750px) {
   .mt-4 {
      margin-bottom: 20px;
   }
   .me-2 {
      margin-right:0px!important;
   }
   .sponsored-text2_mobile {
   padding-bottom:8px;
   }
   .Ktlw8e_mobile{
      padding:8px 0!important;
   }
   .serach_result_main_mobile a h1 {
      margin-top:4px;
   }
   .snippetdivmobile {
    margin-top: 0px;
}
  .mobile_mockup {
    display: block; /* Ensure it's visible */
  }
}
.googlelogoimg {
    margin-top: 10px;
    height: 30px;
}
@media only screen and (min-width: 1500px) {
.mobile_mockup {
  right: 200px; /* Adjust to control distance from the right */
}
}
@media only screen and (min-width: 1700px) {
.mobile_mockup {
  right: 300px; /* Adjust to control distance from the right */
}
   .desktoplarge {
    min-width: 1330px!important;
}
   .frame-image-sc {
    scale: 1.05 !important;
}
   
}
.iCzAIb_mobile {
    font-weight: 400;
    letter-spacing: 0.1px;
    margin-top: 4px;
    margin-bottom: 4px;
}
.extractsett {
   margin-top:30px;
   margin-bottom:20px;
}
.amountofads {
   background: rgba(38, 38, 38, 0.7) !important;
   color: rgb(255, 255, 255) !important;
   box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px inset, rgb(38, 38, 38) 0px 0px 0px 1px inset !important;
   border: none;
   font-size: 14px;
   padding: 8px;
   width: 40px;
   text-align: center;
   border: 1px solid transparent;
   margin-left:10px;
}
.moresettingscon {
   max-width: 1030px!important;
}
.dropdown-icon {
   border: solid gray;
   border-width: 0 2px 2px 0;
   display: inline-block;
   padding: 3px;
   transform: rotate(45deg);
   margin-left: 5px;
   vertical-align:3px;
   transition: transform 0.3s ease;
}
.extractcost {
   margin-top:20px;
   margin-left:-5px;
}
.newitemssettings {
   position:absolute!important;
   display:flex;
   margin-top:200px;
}
@media (max-width: 767px) {
   .extractcost {
      position:relative!important;
      margin-top:-35px;
      margin-bottom:30px;
   }
   .newitemssettings {
      position:relative!important;
      display:grid!important;
      grid-template-columns: repeat(2, auto); /* Two columns */
gap: 5px; /* Space between items */
align-items: center;
   }   
   .label-sc1 {
      margin-bottom:30px;
   }   
   .moresettingstxt{
      line-height:2.5;
   }   
   .extractsett {
      margin-top: 30px;
    margin-bottom: 80px;
   }
}
.custom-arrow {
   font-size: 35%; /* Decrease size by approximately 60% */
   vertical-align: text-bottom; /* Align to the bottom of the text line */
   display: inline-block; /* Ensures it behaves like an inline element */
   margin-bottom:5px;
   margin-left:-5px;
   margin-right:-5px;
}
.tooltip-container {
   position: relative;
   display: inline-block;
   cursor: pointer;
  transform: scale(0.8); /* Scale down everything by 0.8 */
  transform-origin: center; /* Ensures scaling happens from the center */
 }
 
 .question-mark {
   display: inline-block;
   width: 24px; /* Adjust size as needed */
   height: 24px; /* Adjust size as needed */
   background-color: #262626b3; /* Background circle color */
   color: #fff; /* Question mark color */
   border-radius: 50%; /* Makes it a circle */
   text-align: center;
   line-height: 24px; /* Matches the height for vertical centering */
   font-size: 14px; /* Adjust size as needed */
   font-weight: bold;
   margin-left: 10px; /* Adjust padding as needed */
 }
 
 .tooltip-text {
   visibility: hidden;
   width: 200px; /* Adjust width as needed */
   background-color: #333;
   color: #fff;
   text-align: center;
   border-radius: 5px;
   padding: 10px;
   position: absolute;
   bottom: 125%; /* Adjust position */
   left: 50%;
   transform: translateX(-50%);
   z-index: 1;
   opacity: 0;
   transition: opacity 0.3s;
 }
 
 .tooltip-container:hover .tooltip-text,
 .tooltip-container:focus-within .tooltip-text,
 .tooltip-container:active .tooltip-text {
   visibility: visible;
   opacity: 1;
 }
 .csv-icon {
   display: inline-block;
   width: 40px; /* Original width */
   height: 20px; /* Original height */
   border: 1px solid white;
   color: white;
   font-size: 12px; /* Original font size */
   font-weight: bold; /* Keeps bold */
   text-align: center;
   line-height: 20px; /* Matches original height for vertical centering */
   border-radius: 3px; /* Original slight rounding */
   background-color: transparent; /* Transparent background */
   transform: scale(0.7); /* Uniformly scales the icon down */
   transform-origin: center center; /* Ensures scaling starts from the top left */
   margin-right:-10px;
   margin-left:5px;
}
.nextinstead{
   font-size:14px!important;
 }
@media (max-width: 767px) {
    .moresettingstxt {
        font-size: 11px;
    }
}
.h1mobile {
   margin-left:5px;
   margin-right:5px;
 }
 
 .custominform{
   height:100px;
   width:360px;
   resize:none;
   border: 1px solid transparent;
   overflow: hidden;
}

.custom-label{
   background-color: #ffffff0f;
   border-radius:5px;
   color:#fff;
   font-size:14px;
   padding:8px 16px;
   margin-right:10px;
   margin-bottom:12px;
   font-weight:500;
 }
 .custom-label input{
   margin-right:8px;
   accent-color:black;
 }
 .settingsselect{
max-width:200Px;
text-align:left;
font-weight:450;
font-size:14px;
 }
 .moresettingsgen {
   display:flex;
   align-items:center;
   justify-content: center;
   gap:20px;
   margin-top:-30px;
   margin-bottom:30px;
}
.selectcm ,.selectcp{
   background-color:#ffffff0f;
   border-color: transparent;
   padding:10px 10px 10px 10px;
   width:180px;
   appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;  
    font-size:14px; 
}
option {
   background-color:#ffffff0f;
}
.selectcm
{
   margin-bottom:15px;
}

.custom-dropdown-options {
   position: absolute;
   text-align:left;
   background:rgba(20, 20, 20, 0.8) !important;
   margin-left:-10px;
   margin-top:11px;
   margin-bottom:-20px;
   font-size:14px;
   width:180px;
   z-index:999;
   max-height: 130px; /* Set maximum height */
  overflow-y: auto;
 }
 .custom-dropdown-optionsdiv{
   position:relative;
   background-color:#000;
}
 .custom-dropdown-option{
   padding:10px;
   padding-left:10px;
   font-size:14px;
}
.custom-dropdown-option:hover {
   background-color: rgba(255, 255, 255, 0.1); /* Highlighted background */
   color: #fff; /* Ensure text color contrasts with the background */
 }

/* Custom scrollbar */
.custom-dropdown-options::-webkit-scrollbar {
   width: 8px; /* Width of the scrollbar */
 }
 
 .custom-dropdown-options::-webkit-scrollbar-thumb {
   background: rgba(255, 255, 255, 0.2); /* Scrollbar color */
   border-radius: 4px; /* Rounded edges */
 }
 
 .custom-dropdown-options::-webkit-scrollbar-thumb:hover {
   background: rgba(255, 255, 255, 0.4); /* Scrollbar hover color */
 }
 
 .custom-dropdown-options::-webkit-scrollbar-track {
   background: rgba(20, 20, 20, 1); /* Scrollbar track background */
 }
 .custom-dropdown-selected {
   background-color: transparent;
   color: inherit;
   display: flex;
   justify-content: space-between;
   align-items: center;
   position: relative;
 }
 
 .custom-dropdown-chevron {
   width: 0;
   height: 0;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-top: 5px solid #555; /* Arrow color */
   margin-right:3px;
   transition: transform 0.2s ease;
 }
 
 .custom-dropdown-open .custom-dropdown-chevron {
   transform: rotate(180deg); /* Rotate upward when dropdown is open */
 }
 
.moresettingsgentxt{
   margin-top:20px;
   margin-bottom:20px;
   opacity:0.75;
}
/* Fade-in/Fade-out Animations */
.moresettingsgen {
   opacity: 0;
   transform: translateY(-10px);
   transition: opacity 0.3s ease, transform 0.3s ease;
   pointer-events: none;
}

.moresettingsgen.fade-in {
   opacity: 1;
   transform: translateY(0);
   pointer-events: auto;
}

.moresettingsgen.fade-out {
   opacity: 0;
   transform: translateY(-10px);
   pointer-events: none;
}

/* Icon Animation */
.dropdown-icon {
   display: inline-block;
   transition: transform 0.3s ease, vertical-align 0.3s ease;
}
/* Make the entire label clickable */
.clickable-label {
   display: flex;
   align-items: center;
   cursor: pointer;
   padding: 8px 10px;
   border: 1px solid transparent;
   transition: background-color 0.2s ease, border-color 0.2s ease;
}

/* Hidden default checkbox */
.clickable-label input[type="checkbox"] {
   display: none;
}


/* Hover effect for the entire label */
.clickable-label:hover {
   background-color: rgba(0, 0, 0, 0.1);
   border-color: #333;
}
.clickable-label:checked{
   border-color: #333;
}
/* Style for label-sc */
.label-sc {
   display: flex;
   font-weight: 500;
   border-radius: 5px;
   align-items: center;
   background-color: transparent; /* Default: No background when unchecked */
   color: #a7aab0;
   margin-right: 10px;
   font-size: 14px;
   padding: 8px 16px;
   margin-bottom: 12px;
   transition: background-color 0.2s ease;
   pointer-events:auto;
   opacity:0.5;
   transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Hide the input checkbox */
.label-sc input {
   display: none; /* Hide the checkbox */
   margin-right: 8px;
   accent-color: rgb(0, 0, 0);
}

/* Remove the box (custom checkbox) */
.label-sc .custom-checkbox {
   display: none;
}

.label-sc.checked {
   background-color: #ffffff0f; /* Background when checked */
   opacity:1;
}

/* Default styling for label-sc */
.label-sc {
   background-color: transparent; /* Default: No background when unchecked */
}
.labelall{
   display:none;
}
.thirdsettingsline{
text-align:left;
}
.charactercountert{
   float: right; /* Position relative to the closest positioned ancestor */
  margin-top: -22px; /* Adjust the vertical offset */
  margin-right:6px;
  font-size: 12px; /* Optional: size of the counter text */
  color: #666; /* Optional: text color */
  pointer-events: none;
}
.question-mark1{
   color:gray;
}
.newitemssettings {
   transition: opacity 0.3s ease, transform 0.3s ease;
   opacity: 0; /* Hidden by default */
   transform: translateY(10px); /* Slightly moved down initially */
   pointer-events: none; /* Disable interaction initially */
 }
 
 .newitemssettings.visible {
   opacity: 1; /* Fully visible */
   transform: translateY(0); /* Reset position */
   pointer-events: auto; /* Enable interaction */
 }
 .campaign-mode-toggle {
   display: none;
   align-items: center;
   justify-content: center;
   gap: 5px;
 }
 
 .arrow-button {
   background: transparent;
   border: none;
   cursor: pointer;
   padding: 8px;
   transition: transform 0.2s ease, opacity 0.2s ease;
 }
 
 .arrow-button:hover .arrow-icon {
   opacity: 0.8;
   transform: scale(1.2); /* Slightly larger arrow on hover */
 }
 
 .arrow-icon {
   transition: transform 0.2s ease, opacity 0.2s ease;
   opacity:0.5;
 }
 
 .campaign-mode-text {
   font-size: 16px;
   color: #a7aab0;
   min-width: 230px;
   text-align: center;
   cursor: pointer;
 }

.oneofthetwo {
   position:relative;
   display:inline-block,
}
 /* Span styles (Advanced Mode) */
.turnonadvanced {
   opacity: 1;
   cursor: pointer;
   transition: opacity 0.4s ease;
   transition-delay:100ms;
}

.turnonadvanced.hidden {
   opacity: 0;
   pointer-events: none; /* Disable interaction when hidden */
}

/* Dropdown Icon */
.dropdown-icon {
   display: none;
   margin-left: 7px;
   transition: transform 0.3s ease, vertical-align 0.3s ease;
   transform: rotate(225deg);
   vertical-align: -2px;
}

.dropdown-icon.rotated {
   transform: rotate(48deg);
   vertical-align: 3px;
}

/* Campaign Mode Toggle */
.campaign-mode-toggle {
   display: flex;
   margin-top:-25px;
   opacity: 0;
   transition: opacity 0.5s ease, transform 0.5s ease;
   transition-delay: 400ms;
   pointer-events: none;
   visibility: hidden;
}

.campaign-mode-toggle.visible {
   display: flex;
   opacity: 1;
   transform: translateY(0);
   pointer-events: auto;
}
.btn-text {
   display: inline-block;
   opacity: 1;
   transition: opacity 0.5s ease; /* Smooth opacity transition */
}

.btn-text.hidden {
   opacity: 0; /* Fade out */
}
.closeadvanced {
   position:fixed;
   top:175%;
   cursor: pointer;
   transition: transform 0.2s ease, opacity 0.2s ease;
   font-weight:500;
   opacity:0.3;
}
.closeadvanced:hover{
   transform: scale(1.2);
   transition: transform 0.2s ease, opacity 0.2s ease;
   opacity:0.6;
}
.closeadvancd {
   transform: rotate(180deg); /* Flips the arrow */
   display: inline-block; /* Required for transform to work */
   position: absolute;
   cursor: pointer;
   left: -10%;
   padding-bottom: 6px;
   transition: transform 0.2s ease, opacity 0.2s ease;
   opacity: 0.3;
 }
 
 .closeadvancd:hover {
   transform: scale(1.1) rotate(180deg); /* Ensures it scales correctly while flipped */
   opacity: 0.4;
 }
/* Remove arrows in number inputs */
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}
 .menu-settings{
   display:flex;
   z-index:999;
 }
 .oldsettingsh{
   display:none;
 }
 .label-sc.checked {
   background-color: rgba(38, 38, 38, 0.7);
   color: #a7aab0;
   border-color: transparent;
 }
.campaign-mode-toggle{
   display:none!important;
}
.switchtoadvanced{
   opacity:0.8;
   transition: opacity 0.2s ease;
}
.switchtoadvanced:hover{
   opacity:1;
}
.new-badge {
   background-color: red;
   color: white;
   font-size: 10px;
   font-weight: bold;
   padding: 2px 4px;
   margin-left: 12px; /* Space between text and badge */
   border-radius: 4px;
   display: inline-block;
   vertical-align: middle; /* Align with text */
 }
.tooltip-container1{
   opacity:0.85;
}
.tool-tip-title{
   margin-bottom:10px;
}
.minus-sign{
   cursor: pointer;
   padding-top:10px;
   padding-bottom:10px;
   padding-left:7px;
   padding-right:7px;
   margin-right:-7px;
}
.plus-sign{
   cursor: pointer;
   padding-bottom:10px;
   padding-top:10px;
   padding-left:7px;
   padding-right:7px;
}
.minus-sign-amount{
   margin-left:28px;
}
.label-keywords {
}
.footerbtn {
   float:right;
   padding:10px;
   width:140px;
}
.fullscreen-container {
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center!important;
   align-items: center!important;
   overflow: hidden;
   position: fixed;
   top: 0;
   left: 0;
   right:0;
   bottom: 0;
   margin: auto;
   pointer-events:auto;
   z-index:1;
}

.centered-content {
   text-align: center;
   width: 100%;
   max-width: 1300px;
}

.hide-footer .footer {
   display: none !important;
}

.desktop-only {
   display: block;
}

@media (max-width: 991px) {
   .desktop-only {
      display: none;
   }
}
.settings-wrapper {
   margin-top:-5px;
   position: relative;
   width: 100%;
   height: 60px; /* Adjust based on button size */
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .settingsitems {
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   width: fit-content;
 }
 /* === Futuristic Glowy Black & White Slider === */

.slider-container {
   position: relative;
   width: 100%;
   max-width: 370px;
   margin-top:2.7rem;
   margin-left:auto;
   margin-right:auto;
   user-select: none; /* Prevent text selection */
   touch-action: none; /* Prevent mobile gestures interfering */
 }
 
 .slider {
   -webkit-appearance: none;
   width: 100%;
   height: 1px;
   background: rgba(255, 255, 255, 0.15);
   border-radius: 10px;
   user-select: none;
   pointer-events: auto; /* Ensure it can be clicked */
   accent-color:none;
   outline:none;
 }
 
 .slider:before , .slider::after {
   border:none!important;
   accent-color:none;
   outline:none;
 }
 .slider-container input:focus {
   border:none!important;
   accent-color:none;
   outline:none;
 }
 
 .slider::-webkit-slider-thumb {
   -webkit-appearance: none;
   appearance: none;
   width: 10px;
   height: 10px;
   background: white;
   border: 2px solid rgba(255, 255, 255, 0.8);
   border-radius: 50%;
   cursor: pointer;
   box-shadow: 0 0 25px rgba(255, 255, 255, 0.8);
   transition: transform 0.2s, box-shadow 0.2s;
 }
 
 .slider::-webkit-slider-thumb:hover {
   transform: scale(1.2);
 }
 
 /* Prevent dragging issues */
 .slider::-webkit-slider-thumb:active {
   cursor: grabbing;
 }
 
 .slider-container, .slider {
   user-select: none;
   -webkit-user-drag: none;
   -webkit-touch-callout: none;
 }
 .corner-wrap {
   position: fixed;
   width: 0;
   height: 0;
   z-index: 998;
 }
 
 /* ✨ Adds the fading gradient */
 .corner-wrap::before {
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   background: radial-gradient(
     closest-side, 
     rgba(255, 255, 255, 0) 80%  ,
     rgba(255, 255, 255, 0.7) 10%,  /* Brightest near the 90-degree corner */
   );
   pointer-events: none; /* Prevents interaction issues */
 }
 
 /* TOP LEFT */
 .top-left {
   position: fixed;
   top: 0;
   left: 0;
   width: 40px;  /* Triangle size */
   height: 40px;
   background: radial-gradient(
      circle at top left,  
      rgba(255, 255, 255, 0.3) 10%,  /* Brightest at the 90-degree corner */
      rgba(255, 255, 255, 0) 80%  /* Fully transparent at the outer edge */
   );
   clip-path: polygon(0 0, 100% 0, 0 100%); /* Creates triangle shape */
   pointer-events: none;
}/* ✅ TOP LEFT */
.top-left {
   position: fixed;
   top: 0;
   left: 0;
   width: 40px;  /* Triangle size */
   height: 40px;
   background: radial-gradient(
      circle at top left,  
      rgba(255, 255, 255, 0.115) 10%,  /* Brightest at the 90-degree corner */
      rgba(255, 255, 255, 0) 80%  /* Fully transparent at the outer edge */
   );
   clip-path: polygon(0 0, 100% 0, 0 100%); /* Creates triangle shape */
   pointer-events: none;
}

/* ✅ TOP RIGHT */
.top-right {
   position: fixed;
   top: 0;
   right: 0;
   width: 40px;
   height: 40px;
   background: radial-gradient(
      circle at top right,  
      rgba(255, 255, 255, 0.115) 10%,  
      rgba(255, 255, 255, 0) 80%  
   );
   clip-path: polygon(100% 0, 0 0, 100% 100%);
   pointer-events: none;
}

/* ✅ BOTTOM RIGHT */
.bottom-right {
   position: fixed;
   bottom: 0;
   right: 0;
   width: 40px;
   height: 40px;
   background: radial-gradient(
      circle at bottom right,  
      rgba(255, 255, 255, 0.115) 10%,  
      rgba(255, 255, 255, 0) 80%  
   );
   clip-path: polygon(100% 100%, 0 100%, 100% 0);
   pointer-events: none;
}

/* ✅ BOTTOM LEFT */
.bottom-left {
   position: fixed;
   bottom: 0;
   left: 0;
   width: 40px;
   height: 40px;
   background: radial-gradient(
      circle at bottom left,  
      rgba(255, 255, 255, 0.115) 10%,  
      rgba(255, 255, 255, 0) 80%);
   clip-path: polygon(0 100%, 100% 100%, 0 0);
   pointer-events: none;
}
.exitauthenticated {
   position:fixed;
   bottom:12vh;
   left: 20%;
   transform: rotate(180deg);
   cursor:pointer;
 }
 .exitauthenticated:hover {
   transform: rotate(180deg), scale(1.2);
 }
 

 
 .settingslines {
   margin-top:30px!important;
   margin-bottom:20px;
 }

 .settingslines::after , .settingslines::before{
   width: calc(50% - 55px);
 }

 .settingsselectc{
   gap:30px;
   max-width:500px;
 }
.selectcpc , .selectcmc{
   margin-bottom:15Px;
   width:220px;
   height:43px; }
 .amountscc{
   gap: 30px;
 }
 .minusc{
   padding:0px;
 }
 
 /* ====== Special Instructions Menu ====== */
/* ====== Textarea Wrapper (Centers Content) ====== */
.dynamic-textarea-wrapper {
   display: flex; /* Side-by-side layout */
   align-items: center; /* Keep it vertically centered */
   justify-content: center; /* Ensure centering in the parent container */
   width: fit-content; /* Ensures it doesn't stretch */
   margin: 0 auto; /* Keeps everything centered */
 }
 
 /* ====== Special Instructions Menu (Placed to the Right) ====== */
 .special-instructions-menu {
   display: grid;
   grid-template-columns: repeat(2, 1fr); /* 3x2 grid */
   grid-template-rows: repeat(3, 1fr);
   gap: 4px;
   background: #222; /* Dark background */
   padding: 5px;
   border-radius: 5px;
   width: 50px; /* Adjusted for better fit */
   height: 100px; /* Matches textarea height */
   justify-items: center; /* Centering menu buttons */
   order:2;
 }
 .menu-btn {
   font-size: 10px;
   font-weight: bold;
   background: #444;
   color: #ddd;
   border-radius: 3px;
   border: none;
   cursor: pointer;
   padding: 3px;
   width: 100%;
   transition: background 0.2s ease, color 0.5s ease;
 }

.menu-btn.selected{
   background: #222 !important; /* Darker background for selected */
   color: #fff; /* Keep text readable */
   font-weight: bold;
   transition: background 0.2s ease, color 0.5s ease;
 }
 .selected-menu-name{
   position:absolute;
   margin-top:8px;
   font-size:11px;
   float:right;
   right:36%;
   color:#666;
 }
 .custom-dropdown-optionsc {
   width:220px;
 }
 .label-sc1c{
   background-color:#222;
   OPACITY:0.9;
   padding:3px;
   width:220px;
   height:45px;
 }
 .amountofadsc{
   background: none!important;
   margin-left:0px;
   padding-left:0px;
   padding-right:0px;
   pointer-events: none;
   box-shadow:none!important;
 }
 .controlinputc{
   display:flex;
   position:absolute;
   right:76%;
   top:15%;
 }
 .controlinputc2{
   display:flex;
   position:absolute;
   right:77%;
   top:55%;
 }
 .plussignc {
   padding:0px;
   margin-top:16px;
 }
 .amountofadsc2{
   margin-right:50px!important;
   padding-left:8px;
 }
 .mmam{
   margin-left:-8px;
   margin-bottom: 20px;
 }
 .amountofkeywordsc{
   margin-right:10px!important;
   padding-left:8px;
 }
 .fade-in {
   opacity: 1;
   transition: opacity 0.6s ease-in-out;
}

.fade-out {
   opacity: 0;
   transition: opacity 0.6s ease-in-out;
}

.twohorizontally {
   width: 100%;
}

.newsettingsstuff {
   display: grid;
   grid-template-columns: 1fr 2fr 1fr;
   gap: 10px; /* Spacing between columns */
}
.newsettingsstuff2{
   width:340px;
   position: absolute;
   left:46%;
   top:30%;
   border:1px solid white;
   border-radius:10px;
   padding:30px;
   z-index:100;
   background-color: #000;
}
.urlmagnet{
   position: absolute;
   top:65%;
   left:17%;
   width:750px;
}
.yoururl {
 width:320Px;
 float:right;  
} 
  .urlbtn {
   padding: 12px 20px 12px 20px;
  }
.secondsteptitle{
   margin-left:-80px;
}
.instructionsnew { 
  height:180px;
  width:400px;
  margin-top:25px;
}

.instructionstitle{
   position:absolute;
   top:17%;
   font-size:10px;
   background-color:#222;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   padding:5px;
   padding-bottom:3px;
   padding-left:15px;
   padding-right:15px;
}
.columnstwo {
 columns: 2;
 width:620px;
 overflow: visible !important;
}
.generateselect{
   background-color:#222;
   border-radius:10px;
   margin:3px;
   width:290px;
   border: 1px solid transparent;
   background: rgba(38, 38, 38, 0.7) !important;
   box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px inset, rgb(38, 38, 38) 0px 0px 0px 1px inset !important;
  }
