.fullscreen-div {
    max-width: 1440px;
    margin-left:auto;
    margin-right:auto;
    height:120vh;
    top: 0;
    left: 0;
    z-index: 1;
    overflow-y: auto;
}
.titledivv {
    float:left;  
    margin-top:19vh;
   }
   .urlmagnetformdiv{
    margin-top:30vh;
 }
 .desktopmock{
    margin-top:17vh;
    transition: transform 0.7s ease-out;
 }
 .mobilemock{
    margin-top:17vh;
    transition: transform 0.7s ease-out;
 }
 .started{
    transform: translateY(29vh);
 }
 .steponecompleted{
    display:none;
    position:relative;
 }
 .txtar{
    scale:0.94;
 }
 .authsuccessmaydownload{
   opacity:0;
}
 .stepsevenbtn{
    opacity:0;
    pointer-events:none;
    z-index:0;
    position: absolute;
    left:53%;
    padding: 12px 20px 12px 20px;
    top:57%
}
.authlockbtn{
   margin-top:30px;
   margin-bottom:30px;
   padding: 12px 20px 12px 20px;
}
.needmorehelp{
    opacity:0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left:63%;
    font-size:14px;
    top:58.5%
}
.thegif{
    opacity:0;
    visibility: hidden;
    position:absolute;
   top:67%;
   left:53%;
   right:50%;
   background-color:#333;
   width:520px;
   height:300px;
   border-radius: 5px;
   border: 1px solid #333

 }
 .thesecondstep {
    opacity:0;
    visibility:hidden;
    z-index:0;
    position: absolute;
    top:20%;
    left:17%;
    width:1050px;
 }
 .special-instructions-menunew {
    display:inline-flex;
    width:auto;
    height:auto;
    position:absolute;
    top:0%;
    z-index: 500;
   }
   .menubtnnew{
    padding-left:8px;
    padding-right:8px;
    font-weight:500;
   }
   .steptwobtn {
    margin-top:15px;
    margin-left:160px;
    padding: 12px 20px 12px 20px;
 }
.showsecond {
    display:block;
 }
 .steptwocompleted{
    display:none;
 }
 .mobilemockupduringstepthree{
    transform: translateX(-57vh);
   }
.stepseven{
    opacity:0;
    visibility:hidden;
    z-index:0;
    pointer-events:none;
    position:absolute;
   top:37%;
   left:53%;
   right:50%;

   width:300px;
   height:250px;
   border-radius: 5px;
   }
   .thethirdstep {
    opacity:0;
    visibility:hidden;
    z-index:0;
   }

   .viewthird{
    display:block;
 }
 .thirdsteptitle{
    position: absolute;
    left:80%;
    right:0;
    top:24%
   }
   .stepthreebutton{
    position: absolute;
    left:60%;
    padding: 12px 20px 12px 20px;
    top:45%
   }
   .extractforms{
    position: absolute;
    left:73%;
    top:37%
   }

   .mobilemockupduringstepfour{
    transform: translateX(-73vh);
    }
.stepfournodisplay{
   display:none;
  }
  .thefourthstep{
    opacity:0;
    visibility:hidden;
    pointer-events:auto;
   }
  .showfour{
    display:block;
   }
.stepfivebutton {
   margin-top:130px;
   opacity:0;
   visibility:hidden;
}
.stepsixhalfbtn{
   margin-top:250px;
   opacity:0;
   visibility:hidden;
}
   .fourthsteptitle{
   position:absolute;
   top:12%;
   left:53%;
   right:50%;
  }
  .fourthstepdesc{
   position:absolute;
   top:37%;
   left:53%;
   right:50%;
   width:500px;
  }
  .threestepsdiv{
   background-color:#333;
   width:300px;
   height:60px;
   top:57%;
   left:53%;
   right:50%;
   position:absolute;
  }
.fourthsteptitle{
   position:absolute;
   top:26%;
   left:53%;
   right:50%;
  }
  .authdiv{
   position:absolute;
   top:37%;
   left:53%;
   right:50%;
   width:450px;
   height:380px;
   border-radius: 5px;

  }
  .characterlimits{
   visibility: hidden;
   opacity:0;
   position:absolute;
   left:38%;
   margin-top:20vh;
   color:#333;
   width:310px;
   scale:0.8;
 }
  .threestepsdiv{
   background-color:#333;
   width:300px;
   height:60px;
   top:97%;
   left:53%;
   right:50%;
   position:absolute;
  }
  .whatsnextdiv{
   top:37%;
   left:53%;
   right:50%;
   position:absolute;
   opacity:0;
   visibility: hidden;
  }
.thefifthstep {
   visibility: hidden;
    opacity:0;
   }

.hidefour, .hideelement{
    display:none;
   }
   .showfive, .showelement{
    display:block;
   }

.columnsz {
    display:flex;
    columns:2;
    gap:20px;
    position:absolute;
   top:44%;
   left:53%;
   right:50%;
   width:400px;
   height:100px;
   border-radius: 5px;
   border: 1px solid #333
}
.columnsz2{
   opacity:0;
   visibility: hidden;
   display:flex;
   columns:2;
   gap:20px;
   position:absolute;
  top:58%;
  left:53%;
  right:50%;
  width:400px;
  height:100px;
  border-radius: 5px;
  border: 1px solid #333
}
.columnsz3 {
   opacity:0;
   visibility: hidden;
   display:flex;
   columns:2;
   gap:20px;
   position:absolute;
  top:72%;
  left:53%;
  right:50%;
  width:400px;
  height:100px;
  border-radius: 5px;
  border: 1px solid #333
}
.columntwoo{
    float:left;
    width:75%;
    margin-top:15px;
    margin-left:-40px;
}
.columnonee{
    float:right;
    width:25%;
    margin-top:12px;
    margin-left:5px;
    margin-right:5px;
}
.stepfourbutton{
    pointer-events: none;
    position: absolute;
    left:53%;
    padding: 12px 20px 12px 20px;
    top:59%
}
.nodisplayimportant{
    display:none!important;
}
.listoee{
    scale:0.9;
}
.fade-in {
    opacity: 1;
    transition: opacity 0.9s ease-in-out;
    z-index:100!important;
    pointer-events:auto;
    visibility: visible;
 }
 
 .fade-out {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    z-index:0;
    pointer-events: none;
    visibility: hidden;
 }
 .btn {
    z-index:50;
 }
 .thisoneriht{
    pointer-events:auto;
 }
 .specialfade{
    opacity: 1;
    transition: opacity 0.9s ease-in-out;
    pointer-events:auto;
    z-index:101;
 }
 .newestplus{
    pointer-events:auto;
 }
 .newestplus2{
    pointer-events:auto;
 }
 .newestminus{
    pointer-events:auto;
 }
 .newestminus2{
    pointer-events:auto;
 }
 .controlinputc{
    display:flex;
    position:absolute;
    right:5%;
    top:3%;
    pointer-events: auto;
  }
  .controlinputc2{
    display:flex;
    position:absolute;
    right:8%;
    top:55%;
    pointer-events: auto;
  }
  .txtnewi {
    position:relative;
    float:right;
    z-index:100;
 }
 .tooltip-container1{
    position:absolute!important;
    right:10px;
    top:35px;
 }
 .tooltip-text1{
    font-size:14px!important;
    color:#a7aab0;
    width:270px;
    text-align:left;
    height:110px;

    top:100%;
 }
 .smallimgg{
    width:70px;
    height:70px;
 }
 .secondpma{
    margin-top:-10px;
 }
 .nameauthlock{
   width:100px!important;
   min-width:100px!important;
   float:left;
}
.emailauthlock{
   width:250px!important;
   min-width:250px!important;
   float:right;
   margin-top:0px!important;
   margin-bottom:0.5rem;
}
.glowy{
   position:absolute;
   top:70%!important;
   right:50px!important;
   transition: top 0.5s ease-in-out;
}
.glowfirst{
   position:absolute;
   top:100%!important;
   right:50px!important;
   transition: top 0.5s ease-in-out;
}

.ordiv{
   margin-left:10px;
   margin-right:0;
   min-width:355px;
}
.ordiv:after{
   width: calc(100% - 40px);
}

.ordiv:before{
   display:none;
}
.authlocklogin{
   color:#333!important;
   margin-top:-5px;
}
.authlockloginclick{
   color:#555!important;
   cursor: pointer;
}
.authlockloginclick:hover{
   color:#fff!important;
}
.authwallclassmain{
z-index:101;
}
@media screen and (min-width: 1600px) {
   .fullscreen-div {
      width: 1440px;
      height: 125vh;
      margin-left:auto;
      margin-right:auto;
   }
}

/* Scale for 1800px screens */
@media screen and (min-width: 1800px) {
   .fullscreen-div {
      width: 1440px;
      height: 125vh;
      margin-right:auto;
   }
}

.loading-animation {
   /* 2s total:
      - 0%→25%: fades to 0.7 (0.5s)
      - 25%→50%: fades back to 1 (0.5s)
      - 50%→100%: holds at 1 (1s delay) */
   animation: fadeLoading 2s ease-in-out infinite;
 }
 
 @keyframes fadeLoading {
   0% {
     opacity: 1;
   }
   25% {
     opacity: 0.5;
   }
   50% {
     opacity: 1;
   }
   75%{
      opacity: 0.5;
    }
   100% {
     opacity: 1;
   }
 }
 .extract-loading-animation {
   /* 2s total:
      - 0%→25%: fades to 0.7 (0.5s)
      - 25%→50%: fades back to 1 (0.5s)
      - 50%→100%: holds at 1 (1s delay) */
   animation: fadeLoading 2s ease-in-out infinite;
 }
 
 @keyframes fadeLoading {
   0% {
     opacity: 1;
   }
   25% {
     opacity: 0.6;
   }
   50% {
     opacity: 1;
   }
   75%{
      opacity: 0.6;
    }
   100% {
     opacity: 1;
   }
 }
 .extractcost2{
   position:absolute;
   top:60%;
   right:11%;
   z-index:10001;
 }
 .extractcost3{
   position:absolute;
   margin-top:9vh;
   width:300px;
 }
 .linknostyle{
   cursor:pointer;
 }
 .mobilealternativefirstdiv, .mobilealternativeseconddiv{visibility: hidden;
 }

.threestepsect {
   columns:3;
   gap:40px;
   margin-left:10vh;
   margin-right:10vh;
   position:flex;
   margin-bottom:20vh;
   max-height:auto;
   margin-top:-70px;
}
.steponesect {
   border:0.5px solid #333;
   border-radius:5px;
   padding:50px;
   scale:0.8;
}
.steptwosect {
   border:0.5px solid #333;
   border-radius:5px;
   padding:50px;
   scale:0.8;
}
.stepthreesect {
   border:0.5px solid #333;
   border-radius:5px;
   padding:50px;
   scale:0.8;
}
.imagesteps{
   padding-bottom:10px;
   width:200px;
   margin-left:-20px;
   height:auto;
}
.titleinsteps{
   padding-top:5px;
   padding-bottom:20px;
   color:white;
}
@media screen and (max-width: 764px) {
   .fullscreen-div {display:none;
   }
   .mobilealternativefirstdiv{
      text-align:center;
      margin-top:10vh;
      margin-bottom:15vh;
      visibility:visible
   }
   .mobilealternativeseconddiv{
      margin-bottom:15vh;
      visibility:visible;
   }
   .threestepsect {
      columns:1;
      gap:0px;
      margin-left:0vh;
      margin-right:0vh;
      position:flex;
      margin-bottom:8vh;
      max-height:auto;
      margin-top:0vh;
   }
   .sectt {
      border:0.5px solid #333;
      border-radius:5px;
      padding:20px;
      scale:0.9;
      width:300px;
      margin-left:auto;
      margin-right:auto;
   }
   .imagesteps{
      padding-bottom:10px;
      width:100px;
      margin-left:-10px;
      height:auto;
   }
   .titleinsteps{
      padding-top:5px;
      padding-bottom:10px;
      color:white;
   }

   .generatepanother {
      padding-bottom:3vh;
   }
   .thisoneiscool {
      padding-top:8vh;
   }
}
